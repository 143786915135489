import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Logo from "../components/Logo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "introduction"
    }}>{`Introduction`}</h1>
    <p><img alt="npm" src="https://img.shields.io/npm/v/scroom" />{`
`}<a parentName="p" {...{
        "href": "https://github.com/myWsq/scroom"
      }}><img alt="GitHub Repo stars" src="https://img.shields.io/github/stars/myWsq/scroom?style=social" /></a></p>
    <Logo mdxType="Logo" />
    <h2 {...{
      "id": "features"
    }}>{`Features`}</h2>
    <ul>
      <li parentName="ul">{`High Performance`}</li>
      <li parentName="ul">{`Friendly To Use`}</li>
      <li parentName="ul">{`Typrscript Support`}</li>
      <li parentName="ul">{`Lightweight ( 2kb or less by ES Module treeshaking )`}</li>
    </ul>
    <br />
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ npm install scroom
`}</code></pre>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { createScroom } from "scroom";

const sc = createScroom({
  target: document.querySelector(".target-element"),
  offset: 0.5,
});

sc.on("progress", (e) => {
  console.log(e.progress);
});
`}</code></pre>
    <br />
    <h2 {...{
      "id": "auto-resize-detection"
    }}>{`Auto Resize Detection`}</h2>
    <p>{`You don't have to worry about "resize". You can change the size as much as you want, and Scroom will always worked.`}</p>
    <h2 {...{
      "id": "browser-compatibility"
    }}>{`Browser Compatibility`}</h2>
    <p>{`Same with `}<a parentName="p" {...{
        "href": "https://caniuse.com/?search=Intersection%20Observer"
      }}>{`Intersection Observer`}</a>{`. You can optimize the compatibility by including `}<a parentName="p" {...{
        "href": "https://github.com/w3c/IntersectionObserver#readme"
      }}>{`polyfill`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ npm install intersection-observer
`}</code></pre>
    <p>{`And import it.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import "intersection-observer";
`}</code></pre>
    <h2 {...{
      "id": "alternatives"
    }}>{`Alternatives`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/russellgoldenberg/scrollama"
        }}>{`Scrollama`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://scrollmagic.io/"
        }}>{`ScrollMagic`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://imakewebthings.com/waypoints/"
        }}>{`Waypoints`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      